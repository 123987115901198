import { useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Header from "./components/common/Header";
import BaseLayout from "./components/layout/BaseLayout";
import Homepage from "./pages/Homepage/Homepage";
import Impressum from "./pages/Impressum/Impressum";
import Datenschutz from "./pages/Datenschutz/Datenschutz";
import ErrorPage from "./pages/404/ErrorPage";
import AboutPage from "./pages/AboutPage/AboutPage";
import LeitbildPage from "./pages/AboutPage/subpages/LeitbildPage";
import TraditionenPage from "./pages/AboutPage/subpages/TraditionenPage";
import PositionsPage from "./pages/AboutPage/subpages/PositionsPage";
import GebotePage from "./pages/AboutPage/subpages/GebotePage";
import HistoryPage from "./pages/AboutPage/subpages/HistoryPage";
import ConsultingPage from "./pages/ConsultingPage/ConsultingPage";
import SchoolPage from "./pages/SchoolPage/SchoolPage";
import KigaPage from "./pages/KindergardenPage/KigaPage";
import HortPage from "./pages/HortPage/HortPage";
import ParentsPage from "./pages/ParentsPage/ParentsPage";
import ClubPage from "./pages/ClubPage/ClubPage";
import WitchHousePage from "./pages/WitchHousePage/WitchHousePage";
import CookieBox from "./components/common/CookieBox";
import ScrollToHashElement from "./components/common/SmoothScroll";
import Kontakt from "./pages/Kontakt/Kontakt";
import Blog from "./pages/Blog/Blog";
import { fetchData } from "./components/common/api/request";
import { useQuery } from "react-query";

function App() {
  const [visible, setVisible] = useState(false);
  const [presence, setPresence] = useState(false);

  useEffect(() => {
    setPresence(true);
    setVisible(true);
  }, []);

  const handleAcceptAll = () => {
    localStorage.setItem("Nachbarschaftsschule Website Cookiebox", "true");
    setVisible(!visible);
    setTimeout(() => {
      setPresence(!presence);
    }, 550);
  };

  const handleAcceptNecessary = () => {
    setVisible(!visible);
    setTimeout(() => {
      setPresence(!presence);
    }, 550);
  };

  const { data } = useQuery("dataFooter", fetchData);

  return (
    <main className="relative overflow-y-scroll h-screen font-plaintext">
      {/* {presence && data && (
        <CookieBox
          data={data?.cookiebox}
          visible={visible}
          handleAcceptNecessary={handleAcceptNecessary}
          handleAcceptAll={handleAcceptAll}
        />
      )} */}
      <div
        className={
          "flex flex-col w-full min-h-screen items-start justify-start overflow-hidden"
        }
      >
        <Header />
        <ScrollToHashElement />
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/überuns" element={<AboutPage />} />
          <Route path="/überuns/leitbild" element={<LeitbildPage />} />
          <Route path="/überuns/traditionen" element={<TraditionenPage />} />
          <Route path="/überuns/stellen" element={<PositionsPage />} />
          <Route path="/überuns/10gebote" element={<GebotePage />} />
          <Route path="/überuns/geschichte" element={<HistoryPage />} />
          <Route path="/beratung" element={<ConsultingPage />} />
          <Route path="/schule" element={<SchoolPage />} />
          <Route path="/kindergarten" element={<KigaPage />} />
          <Route path="/hort" element={<HortPage />} />
          <Route path="/elternrat" element={<ParentsPage />} />
          <Route path="/verein" element={<ClubPage />} />
          <Route path="/hexenhaus" element={<WitchHousePage />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/kontakt" element={<Kontakt />} />
          <Route path="/impressum" element={<Impressum />} />
          <Route path="/datenverarbeitung" element={<Datenschutz />} />
          <Route
            path="/404"
            element={
              <BaseLayout title="Page Not Found" children={<ErrorPage />} />
            }
          />
          <Route path="*" element={<Navigate to="/404" />} />
        </Routes>
      </div>
    </main>
  );
}

export default App;
